import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import logo from '../images/isologo-negative.png';
import $ from 'jquery';
import checkRole from '../utils/checkRole';

const Sidebar = (props) => {
    useEffect(() => {
        $(".js__accordion").each(function () {
            var selector = $(this);
            selector.find(".js__control").on("click", function (event) {
                event.preventDefault();
                if ($(this).parent().hasClass("active")) {
                    $(this).parent().removeClass("active");
                    $(this).next().stop().slideUp(400);
                } else {
                    selector.find(".active").children(".js__content").stop().slideUp(400);
                    selector.find(".active").removeClass("active");
                    $(this).parent().addClass("active");
                    $(this).next(".js__content").slideDown(400);
                }
            });
        });

        $(".js__menu_mobile").on("click", function () {
            $("html").toggleClass("menu-active");
            $(window).trigger("resize");
        });

        $(".js__menu_close").on("click", function () {
            $("html").removeClass("menu-active");
        });

        $("body").on("click", function (event) {
            if ($("html.menu-active").length && $(window).width() < 800) {
                var selector = $(event.target);
                if (!(selector.hasClass("main-menu") || selector.hasClass("js__menu_mobile") || selector.parents(".main-menu").length || selector.parents(".js__menu_mobile").length)) {
                    $("html").removeClass("menu-active");
                }
            }
        });
    }, []);

    return (
        <section>
            <div className="main-menu">
                <header className="header">
                    <NavLink to='/' className="logo"><img src={logo} alt="ReachOut" /></NavLink>
                    <button type="button" className="button-close fa fa-times js__menu_close"></button>
                    <div className="user">
                        <NavLink to="/profile" className={`avatar sidebar-profile ${(!props.user.profile_pic || !props.user.profile_pic[0]) && 'bg-gradient-warning d-flex'}`} style={{ backgroundImage: (props.user.profile_pic && props.user.profile_pic[0]) && `url(${props.user.profile_pic[0]})` }}>
                            {
                                (!props.user.profile_pic || !props.user.profile_pic[0])
                                &&
                                <div className="admin-pic-placeholder">
                                    <span className="initials justify-content-center align-self-center">{props?.user?.first_name.split(',').map(n => n[0].toUpperCase())}{props?.user?.last_name.split(',').map(n => n[0].toUpperCase())}</span>
                                </div>
                            }
                            <span className="status online"></span>
                        </NavLink>
                        <h5 className="name"><NavLink to="/profile">{props.user.name}</NavLink></h5>
                        <h5 className="position">{props.user.brand}</h5>
                    </div>
                </header>
                <div className="content">
                    <div className="navigation">
                        <h5 className="title">Application</h5>
                        <ul className="menu js__accordion">
                            <li>
                                <NavLink to="/" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-chart-line"></i><span>Dashboard</span></NavLink>
                            </li>
                            <li>
                                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                <a className="parent-item js__control" href="#"><i className="menu-icon fas fa-credit-card"></i><span>Promos</span><span className="menu-arrow fa fa-angle-down"></span></a>
                                <ul className="sub-menu js__content">
                                    {/*<NavLink to="/promos/new">Create New Promo</NavLink>*/}
                                    <NavLink to="/promos">View Active</NavLink>
                                    <NavLink to="/promos/archived">View Archived</NavLink>
                                </ul>
                            </li>
                            <li>
                                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                <a className="parent-item js__control" href="#"><i className="menu-icon fas fa-sticky-note"></i><span>Contents</span><span className="menu-arrow fa fa-angle-down"></span></a>
                                <ul className="sub-menu js__content">
                                    {/*<NavLink to="/contents/new">Create New Content</NavLink>*/}
                                    <NavLink to="/contents">View Active</NavLink>
                                    <NavLink to="/contents/archived">View Archived</NavLink>
                                </ul>
                            </li>
                            <li>
                                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                <a className="parent-item js__control" href="#"><i className="menu-icon fas fa-store"></i><span>Shops</span><span className="menu-arrow fa fa-angle-down"></span></a>
                                <ul className="sub-menu js__content">
                                    {/*<NavLink to="/contents/new">Create New Content</NavLink>*/}
                                    <NavLink to="/shops">View Active</NavLink>
                                    <NavLink to="/shops/archived">View Archived</NavLink>
                                    <NavLink to="/shops/map">View Map</NavLink>
                                </ul>
                            </li>
                            {
                                props.brand?.rog_loyalty?.enabled && checkRole('missions_read') &&
                                <li>
                                    <NavLink to="/missions" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-space-shuttle"></i><span>Missions</span></NavLink>
                                </li>
                            }
                            {
                                checkRole('notifications_read') &&
                                <li>
                                    <NavLink to="/notifications" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-bell"></i><span>Notifications</span></NavLink>
                                </li>
                            }
                            <li>
                                <NavLink to="/reminders" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-clock"></i><span>Reminders</span></NavLink>
                            </li>
                            {
                                checkRole('faqs_read') &&
                                <li>
                                    <NavLink to="/faqs" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-circle-question"></i><span>FAQ</span></NavLink>
                                </li>
                            }
                            {
                                checkRole('referrers_read') &&
                                <li>
                                    <NavLink to="/referrers" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-asterisk"></i><span>Referrers</span></NavLink>
                                </li>
                            }
                        </ul>
                        <h5 className="title">Lists</h5>
                        <ul className="menu js__accordion">
                            <li>
                                <NavLink to="/redeems" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-barcode"></i><span>Redeems</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/users" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fa fa-users"></i><span>Users</span></NavLink>
                            </li>
                            {
                                checkRole('tickets_read') &&
                                <li>
                                    <NavLink to="/tickets" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-ticket-alt"></i><span>Support Tickets</span></NavLink>
                                </li>
                            }
                            {
                                checkRole('orders_read') &&
                                <li>
                                    <NavLink to="/orders" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-receipt"></i><span>Orders</span></NavLink>
                                </li>
                            }
                        </ul>
                        {
                            checkRole('loyalty_read') &&
                            <>
                                <h5 className="title">Loyalty</h5>
                                <ul className="menu js__accordion">
                                    <li>
                                        <NavLink to="/loyalty/blacklist" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-shopping-bag"></i><span>Loyalty Blacklist</span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/loyalty/rules" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-ruler"></i><span>Loyalty Rules</span></NavLink>
                                    </li>
                                    {
                                        checkRole('loyalty_write') &&
                                        <li>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a className="parent-item js__control" href="#"><i className="menu-icon fas fa-star"></i><span>Bulk Actions</span><span className="menu-arrow fa fa-angle-down"></span></a>
                                            <ul className="sub-menu js__content">
                                                <NavLink to="/loyalty/movements/bulk">Add Movements</NavLink>
                                                {/* <NavLink to="/loyalty/balance/bulk">Change Balances</NavLink> */}
                                            </ul>
                                        </li>
                                    }
                                    <li>
                                        <NavLink to="/loyalty/tiers/structures" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-layer-group"></i><span>Tiers Structures</span></NavLink>
                                    </li>
                                </ul>
                            </>
                        }
                        <h5 className="title">Assets</h5>
                        <ul className="menu js__accordion">
                            {
                                checkRole('audiences_read') &&
                                <li>
                                    <NavLink to="/audiences" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-user-tag"></i><span>Audiences</span></NavLink>
                                </li>
                            }
                            <li>
                                <NavLink to="/media" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-camera"></i><span>Media</span></NavLink>
                            </li>
                            {/* <li>
                                <NavLink to="/categories" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-tags"></i><span>Categories</span></NavLink>
                            </li> */}
                        </ul>
                        <h5 className="title">Analytics</h5>
                        <ul className="menu js__accordion">
                            {
                                /*
                                <li>
                                    <NavLink to="/reports/beacons" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fab fa-bluetooth"></i><span>Beacons</span></NavLink>
                                </li>
                                */
                            }
                            {
                                checkRole('rfm_read') &&
                                <li>
                                    <NavLink to="/rfm/analysis" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-layer-group"></i><span>RFM Analysis</span></NavLink>
                                </li>
                            }
                            <li>
                                <NavLink to="/reports/coupon_usage" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-qrcode"></i><span>Coupon Usage</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/reports/redeems" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-percentage"></i><span>Redeemed Promos</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/reports/users" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-id-badge"></i><span>Users</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/reports/users_by_redeems" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-id-card"></i><span>Users By Redeems</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/reports/referrers" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-sign"></i><span>User Referrers</span></NavLink>
                            </li>
                        </ul>
                        {
                            (checkRole('bills_read') || checkRole('bills_write')) &&
                            <div>
                                <h5 className="title">Billing</h5>
                                <ul className="menu js__accordion">
                                    {
                                        checkRole('bills_read') &&
                                        <>
                                            <li>
                                                <NavLink to="/bills/audit" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-check-to-slot"></i><span>Processed Bills Audit</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/bills/list" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-file-invoice-dollar"></i><span>List of Bills</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/bills/users" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-crown"></i><span>Bills By Users</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/bills/locationList" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-cash-register"></i><span>Bills By Location</span></NavLink>
                                            </li>
                                        </>
                                    }
                                    {
                                        // Change for bills_write
                                        checkRole('bills_write') &&
                                        <li>
                                            <NavLink to="/bills/pendingAuthorization" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-list-check"></i><span>Pending Bills</span></NavLink>
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                        {
                            checkRole('audits_read') &&
                            <div>
                                <h5 className="title">Audits</h5>
                                <ul className="menu js__accordion">
                                    <li>
                                        <NavLink to="/audits/loyalty-transactions" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-list-alt"></i><span>Loyalty Transactions</span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/audits/code-reactivations" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-list-alt"></i><span>Code Reactivations</span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/audits/redeems-by-admin" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-list-alt"></i><span>Redeems by Admin</span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/audits/pos-integration" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-list-alt"></i><span>POS Integration</span></NavLink>
                                    </li>
                                </ul>
                            </div>
                        }
                        {
                            (checkRole('brand_read') || checkRole('admins_read')) &&
                            <div>
                                <h5 className="title">Settings</h5>
                                <ul className="menu js__accordion">
                                    {
                                        checkRole('brand_read') &&
                                        <li>
                                            <NavLink to="/settings" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-sliders-h"></i><span>Settings</span></NavLink>
                                        </li>
                                    }
                                    {
                                        checkRole('brand_write') &&
                                        <li>
                                            <NavLink to="/assets" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-images"></i><span>Platform Assets</span></NavLink>
                                        </li>
                                    }
                                    {
                                        checkRole('admins_read') &&
                                        <li>
                                            <NavLink to="/admin-users" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon fas fa-users-cog"></i><span>Admin Users</span></NavLink>
                                        </li>
                                    }
                                    {
                                        checkRole('brand_read') &&
                                        <li>
                                            <NavLink to="/smtp" className={({ isActive }) => isActive ? "current" : ""}><i className="menu-icon far fa-envelope"></i><span>SMTP</span></NavLink>
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => ({ user: state.auth.user, brand: state.brand });

export default connect(mapStateToProps)(Sidebar);
