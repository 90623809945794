/* DEPENDENCIES */
import './styles/main.scss';
import "react-table-6/react-table.css";
import 'bootstrap/dist/js/bootstrap.bundle';
import React, { useEffect, useState, Suspense, lazy } from 'react';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
/* STORE */
import Store from './reducers';
/* COMPONENTS */
import AppContainer from './components/AppContainer';
import checkRole from './utils/checkRole';
const Error403 = lazy(() => import('./components/assets/Error403'));
const Error404 = lazy(() => import('./components/assets/Error404'));
const Admins = lazy(() => import('./components/AdminUser'));
const AdminsEdit = lazy(() => import('./components/AdminUser.Edit'));
const AppAssets = lazy(() => import('./components/AppAssets'));
const AppAssetsEdit = lazy(() => import('./components/AppAssets.Edit'));
const Audiences = lazy(() => import('./components/Audiences'));
const AudiencesNew = lazy(() => import('./components/Audiences.New'));
const AudiencesView = lazy(() => import('./components/Audiences.View'));
const AuditLoyaltySupportTransactions = lazy(() => import('./components/Audits/LoyaltySupportTransactions'));
const AuditCodeReactivations = lazy(() => import('./components/Audits/CodeReactivations'));
const AuditRedeemsByAdmin = lazy(() => import('./components/Audits/RedeemsByAdmin'));
const Bills = lazy(() => import('./components/Bills/Bills'));
const BillDetails = lazy(() => import('./components/Bills/BillDetails'));
const BillsByLocation = lazy(() => import('./components/Bills/BillsByLocation'));
const BillsByUsers = lazy(() => import('./components/Bills/BillsByUsers'));
const BillsAudit = lazy(() => import('./components/Bills/BillsAudit'));
const BillsAuthorizationList = lazy(() => import('./components/Bills/BillsAuthorizationList'));
const Contents = lazy(() => import('./components/Contents'));
const ContentsEdit = lazy(() => import('./components/Contents.Edit'));
const Disabled = lazy(() => import('./components/assets/Disabled'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Faqs = lazy(() => import('./components/Faqs'));
const FaqsEdit = lazy(() => import('./components/Faqs.Edit'));
const Loader = lazy(() => import('./components/assets/Loader'));
const Login = lazy(() => import('./components/Login'));
const LoginTFA = lazy(() => import('./components/Login.TFA'));
const LoyaltyStructures = lazy(() => import('./components/Tiers/Structures'));
const LoyaltyStructuresNew = lazy(() => import('./components/Tiers/Structure.Edit'));
const LoyaltyBlacklist = lazy(() => import('./components/Loyalty/Loyalty.Blacklist'));
const LoyaltyBlacklistNew = lazy(() => import('./components/Loyalty/Loyalty.Blacklist.Add'));
const LoyaltyRules = lazy(() => import('./components/Loyalty/Loyalty.Rules'));
const LoyaltyRulesNew = lazy(() => import('./components/Loyalty/Loyalty.Rules.Add'));
const LoyaltyBlacklistRemove = lazy(() => import('./components/Loyalty/Loyalty.Blacklist.Remove'));
const LoyaltyPointsBalance = lazy(() => import('./components/Loyalty/Loyalty.Points.Balance'));
const LoyaltyPointsMovement = lazy(() => import('./components/Loyalty/Loyalty.Movements.Bulk'));
const Media = lazy(() => import('./components/Media'));
const Missions = lazy(() => import('./components/Missions'));
const MissionsEdit = lazy(() => import('./components/Missions.Edit'));
const MissionsLogs = lazy(() => import('./components/Missions.Logs'));
const Notifications = lazy(() => import('./components/Notifications'));
const NotificationsNew = lazy(() => import('./components/Notifications.New'));
const NotificationsReads = lazy(() => import('./components/Notifications.Reads'));
const Orders = lazy(() => import('./components/Orders'));
const Profile = lazy(() => import('./components/Profile'));
const Promos = lazy(() => import('./components/Promos'));
const PromosEdit = lazy(() => import('./components/Promos.Edit'));
const PromosEmailEdit = lazy(() => import('./components/PromosEmail.Edit'));
const Redeems = lazy(() => import('./components/Redeems'));
const RedeemsUsageBatch = lazy(() => import('./components/Redeems.UsageBatch'));
const Referrers = lazy(() => import('./components/Referrers'));
const ReferrersNew = lazy(() => import('./components/Referrers.New'));
const Reminders = lazy(() => import('./components/Reminders'));
const RemindersEdit = lazy(() => import('./components/Reminders.Edit'));
const ReportBeacons = lazy(() => import('./components/Reports/Beacons'));
const ReportCouponUsage = lazy(() => import('./components/Reports/CouponUsage'));
const ReportRedeems = lazy(() => import('./components/Reports/Redeems'));
const ReportUsers = lazy(() => import('./components/Reports/Users'));
const ReportUsersByRedeems = lazy(() => import('./components/Reports/UsersByRedeems'));
const ReportUserReferrer = lazy(() => import('./components/Reports/UserReferrer'));
const RFMClients = lazy(() => import('./components/RFM/RFM.Clients'));
const RFMDashboard = lazy(() => import('./components/RFM/RFM.Dashboard'));
const RFMthreshholdEdit = lazy(() => import('./components/RFM/RFM.Threshold.Edit'));
const RFMSegmentEdit = lazy(() => import('./components/RFM/RFM.Segment.Edit'));
const RFMExecutions = lazy(() => import('./components/RFM/RFM.Executions'));
const RFMExecutionDetail = lazy(() => import('./components/RFM/RFM.ExecutionDetail'));
const RFMMembers = lazy(() => import('./components/RFM/RFM.Members'));
const RFMUser = lazy(() => import('./components/RFM/RFM.User'));
const Settings = lazy(() => import('./components/Settings'));
const SettingsEdit = lazy(() => import('./components/Settings.Edit'));
const SettingsSchedule = lazy(() => import('./components/SettingsSchedule'));
const Shops = lazy(() => import('./components/Shops'));
const ShopsEdit = lazy(() => import('./components/Shops.Edit'));
const ShopsMap = lazy(() => import('./components/Shops.Map'));
const SMTP = lazy(() => import('./components/SMTP'));
const SMTPEdit = lazy(() => import('./components/SMTP.Edit'));
const Tickets = lazy(() => import('./components/Tickets'));
const TFAEnable = lazy(() => import('./components/TFA.Enable'));
const User = lazy(() => import('./components/User'));
const Users = lazy(() => import('./components/Users'));
const UsersBills = lazy(() => import('./components/Users.Bills'));
const UsersLoyalty = lazy(() => import('./components/Users.Loyalty'));
const UsersRedeems = lazy(() => import('./components/Users.Redeems'));
const UsersLogs = lazy(() => import('./components/Users.Logs'));
const UsersForceRedeems = lazy(() => import('./components/Users.ForceRedeem'));
const PosIntegration = lazy(() => import('./components/Audits/PosIntegration'));

const App = () => {
  const [auth, setAuth] = useState(Store.getState().auth.isAuthenticated);
  const [isActive, setIsActive] = useState(Store.getState().auth.user?.is_active === false ? false : true);
  const [brand, setBrand] = useState(true);
  const [redirect] = useState(window.location.pathname + window.location.search);

  useEffect(() => {
    const login = Store.subscribe(() => {
      setAuth(Store.getState().auth.isAuthenticated);
      setIsActive(Store.getState().auth.user.is_active);
      setBrand(Store.getState().brand.is_active);
    });

    return function cleanup() {
      login();
    };
  }, []);

  return (
    <Provider store={Store}>
      <Loader />
      {(brand === false || isActive === false) && auth && <Disabled />}
      {
        auth
          ?
          (
            <AppContainer>
              <Suspense fallback={<div className="loader"><div className="typing_loader"></div></div>}>
                <Routes>
                  <Route path='/' element={<Dashboard />} />
                  <Route path='/admin-users' element={checkRole('admins_read') ? <Admins /> : <Error403 title={'Admins'} perm={'admin_read'} />} />
                  <Route path='/admin-users/create' element={checkRole('admins_write') ? <AdminsEdit /> : <Error403 title={'Admins Create'} perm={'admin_write'} />} />
                  <Route path='/admin-users/edit/:id' element={checkRole('admins_write') ? <AdminsEdit /> : <Error403 title={'Admins Edit'} perm={'admin_write'} />} />
                  <Route path='/assets' element={<AppAssets />} />
                  <Route path='/assets/edit/:name' element={<AppAssetsEdit />} />
                  <Route path='/audiences' element={checkRole('audiences_read') ? <Audiences /> : <Error403 title={'Audiences'} perm={'audiences_read'} />} />
                  <Route path='/audiences/create' element={checkRole('audiences_write') ? <AudiencesNew /> : <Error403 title={'Audiences Create'} perm={'audiences_write'} />} />
                  <Route path='/audiences/view/:id' element={checkRole('audiences_read') ? <AudiencesView /> : <Error403 title={'Audiences Edit'} perm={'audiences_read'} />} />
                  <Route path='/audits/loyalty-transactions' element={checkRole('audits_read') ? <AuditLoyaltySupportTransactions /> : <Error403 title={'Audits'} perm={'audits_read'} />} />
                  <Route path='/audits/code-reactivations' element={checkRole('audits_read') ? <AuditCodeReactivations /> : <Error403 title={'Audits'} perm={'audits_read'} />} />
                  <Route path='/audits/pos-integration' element={checkRole('audits_read') ? <PosIntegration /> : <Error403 title={'Audits'} perm={'audits_read'} />} />
                  <Route path='/audits/redeems-by-admin' element={checkRole('audits_read') ? <AuditRedeemsByAdmin /> : <Error403 title={'Audits'} perm={'audits_read'} />} />
                  <Route path='/bills/audit' element={checkRole('bills_read') ? <BillsAudit /> : <Error403 title={'Bills'} perm={'bills_read'} />} />
                  <Route path='/bills/list' element={checkRole('bills_read') ? <Bills /> : <Error403 title={'Bills'} perm={'bills_read'} />} />
                  <Route path='/bills/details/:id' element={checkRole('bills_read') ? <BillDetails /> : <Error403 title={'Bills'} perm={'bills_read'} />} />
                  <Route path='/bills/users' element={checkRole('bills_read') ? <BillsByUsers /> : <Error403 title={'Bills'} perm={'bills_read'} />} />
                  <Route path='/bills/locationList' element={checkRole('bills_read') ? <BillsByLocation /> : <Error403 title={'Bills'} perm={'bills_read'} />} />
                  <Route path='/bills/pendingAuthorization' element={checkRole(['bills_read', 'bills_write']) ? <BillsAuthorizationList /> : <Error403 title={'Bills'} perm={'bills_read & bills_write'} />} />
                  <Route path='/contents' element={<Contents archived={false} />} />
                  <Route path='/contents/archived' element={<Contents archived={true} />} />
                  <Route path='/contents/create' element={checkRole('contents_write') ? <ContentsEdit /> : <Error403 title={'Contents Create'} perm={'contents_write'} />} />
                  <Route path='/contents/edit/:id' element={checkRole('contents_write') ? <ContentsEdit /> : <Error403 title={'Contents Edit'} perm={'contents_write'} />} />
                  <Route path='/email/promo/:id' element={checkRole('promos_write') ? <PromosEmailEdit /> : <Error403 title={'Promos Edit'} perm={'promos_write'} />} />
                  <Route path='/faqs' element={checkRole('faqs_read') ? <Faqs /> : <Error403 title={'FAQs'} perm={'faqs_read'} />} />
                  <Route path='/faqs/create' element={checkRole('faqs_write') ? <FaqsEdit /> : <Error403 title={'FAQs'} perm={'faqs_write'} />} />
                  <Route path='/faqs/edit/:id' element={checkRole('faqs_write') ? <FaqsEdit /> : <Error403 title={'FAQs'} perm={'faqs_write'} />} />
                  <Route path='/loyalty/tiers/structures' element={checkRole('loyalty_read') ? <LoyaltyStructures /> : <Error403 title={'Loyalty Structures'} perm={'tiers_read'} />} />
                  <Route path='/loyalty/tiers/structures/create' element={checkRole('loyalty_write') ? <LoyaltyStructuresNew /> : <Error403 title={'Loyalty Structures'} perm={'tiers_write'} />} />
                  <Route path='/loyalty/tiers/structures/:id/edit' element={checkRole('loyalty_write') ? <LoyaltyStructuresNew /> : <Error403 title={'Loyalty Structures'} perm={'tiers_write'} />} />
                  <Route path='/loyalty/blacklist' element={checkRole('loyalty_read') ? <LoyaltyBlacklist /> : <Error403 title={'Loyalty Blacklist'} perm={'loyalty_read'} />} />
                  <Route path='/loyalty/blacklist/add' element={checkRole('loyalty_write') ? <LoyaltyBlacklistNew /> : <Error403 title={'Loyalty Blacklist'} perm={'loyalty_write'} />} />
                  <Route path='/loyalty/blacklist/remove' element={checkRole('loyalty_write') ? <LoyaltyBlacklistRemove /> : <Error403 title={'Loyalty Blacklist'} perm={'loyalty_write'} />} />
                  <Route path='/loyalty/rules' element={checkRole('loyalty_read') ? <LoyaltyRules /> : <Error403 title={'Loyalty Rules'} perm={'loyalty_read'} />} />
                  <Route path='/loyalty/rules/add' element={checkRole('loyalty_write') ? <LoyaltyRulesNew /> : <Error403 title={'Loyalty Rules'} perm={'loyalty_write'} />} />
                  <Route path='/loyalty/points_balance' element={<LoyaltyPointsBalance />} />
                  <Route path='/loyalty/movements/bulk' element={<LoyaltyPointsMovement />} />
                  <Route path='/media' element={<Media />} />
                  <Route path='/missions' element={checkRole('missions_read') ? <Missions /> : <Error403 title={'Missions'} perm={'missions_read'} />} />
                  <Route path='/missions/create' element={checkRole('missions_write') ? <MissionsEdit /> : <Error403 title={'Missions Create'} perm={'missions_write'} />} />
                  <Route path='/missions/edit/:id' element={checkRole('missions_write') ? <MissionsEdit /> : <Error403 title={'Missions Edit'} perm={'missions_write'} />} />
                  <Route path='/missions/:id/logs' element={checkRole('missions_read') ? <MissionsLogs /> : <Error403 title={'Missions Logs'} perm={'missions_read'} />} />
                  <Route path='/notifications' element={checkRole('notifications_read') ? <Notifications /> : <Error403 title={'Notifications'} perm={'notifications_read'} />} />
                  <Route path='/notifications/new' element={checkRole('notifications_write') ? <NotificationsNew /> : <Error403 title={'Nofitications Create'} perm={'notifications_write'} />} />
                  <Route path='/notifications/:id/reads' element={checkRole('notifications_read') ? <NotificationsReads /> : <Error403 title={'Nofitications Readers'} perm={'notifications_read'} />} />
                  <Route path='/orders' element={checkRole('orders_read') ? <Orders /> : <Error403 title={'Orders'} perm={'orders_read'} />} />
                  <Route path='/profile' element={<Profile />} />
                  <Route path='/promos' element={<Promos archived={false} />} />
                  <Route path='/promos/archived' element={<Promos archived={true} />} />
                  <Route path='/promos/create' element={checkRole('promos_write') ? <PromosEdit /> : <Error403 title={'Promos Create'} perm={'promos_write'} />} />
                  <Route path='/promos/edit/:id' element={checkRole('promos_write') ? <PromosEdit /> : <Error403 title={'Promos Edit'} perm={'promos_write'} />} />
                  <Route path='/redeems' element={<Redeems />} />
                  <Route path='/redeems/usage/batch' element={<RedeemsUsageBatch />} />
                  <Route path='/referrers' element={checkRole('referrers_read') ? <Referrers /> : <Error403 title={'Referrers'} perm={'referrers_read'} />} />
                  <Route path='/referrers/create' element={checkRole('referrers_write') ? <ReferrersNew /> : <Error403 title={'Referrers Create'} perm={'referrers_write'} />} />
                  <Route path='/reminders' element={<Reminders />} />
                  <Route path='/reminders/new' element={<RemindersEdit />} />
                  <Route path='/reminders/:id/edit' element={<RemindersEdit />} />
                  <Route path='/reports/beacons' element={<ReportBeacons />} />
                  <Route path='/reports/coupon_usage' element={<ReportCouponUsage />} />
                  <Route path='/reports/redeems' element={<ReportRedeems />} />
                  <Route path='/reports/users' element={<ReportUsers />} />
                  <Route path='/reports/users_by_redeems' element={<ReportUsersByRedeems />} />
                  <Route path='/reports/referrers' element={<ReportUserReferrer />} />
                  <Route path='/rfm/analysis' element={checkRole('rfm_read') ? <RFMDashboard /> : <Error403 title={'RFM Dashboard'} perm={'rfm_read'} />} />
                  <Route path='/rfm/thresholds' element={checkRole('rfm_read') ? <RFMthreshholdEdit /> : <Error403 title={'RFM Thresholds'} perm={'rfm_write'} />} />
                  <Route path='/rfm/segment/:segment_id/edit' element={checkRole('rfm_write') ? <RFMSegmentEdit /> : <Error403 title={'RFM Segment Edit'} perm={'rfm_write'} />} />
                  <Route path='/rfm/executions' element={checkRole('rfm_read') ? <RFMExecutions /> : <Error403 title={'RFM Executions'} perm={'rfm_read'} />} />
                  <Route path='/rfm/executions/:execution_id' element={checkRole('rfm_read') ? <RFMExecutionDetail /> : <Error403 title={'RFM Execution Details'} perm={'rfm_read'} />} />
                  <Route path='/rfm/analysis/:segment_id/clients' element={checkRole('rfm_read') ? <RFMClients /> : <Error403 title={'RFM Clients'} perm={'rfm_read'} />} />
                  <Route path='/rfm/analysis/:segment_id/members' element={checkRole('rfm_read') ? <RFMMembers /> : <Error403 title={'RFM Members'} perm={'rfm_read'} />} />
                  <Route path='/settings' element={checkRole('brand_read') ? <Settings /> : <Error403 title={'Settings'} perm={'brand_read'} />} />
                  <Route path='/settings/scheduled' element={checkRole('brand_read') ? <SettingsSchedule /> : <Error403 title={'Settings'} perm={'brand_read'} />} />
                  <Route path='/settings/:key/schedule' element={checkRole('brand_read') ? <SettingsEdit /> : <Error403 title={'Settings'} perm={'brand_read'} />} />
                  <Route path='/settings/edit/:key' element={checkRole('brand_write') ? <SettingsEdit /> : <Error403 title={'Settings Edit'} perm={'brand_write'} />} />
                  <Route path='/shops' element={<Shops archived={false} />} />
                  <Route path='/shops/archived' element={<Shops archived={true} />} />
                  <Route path='/shops/map' element={<ShopsMap />} />
                  <Route path='/shops/create' element={checkRole('shops_write') ? <ShopsEdit /> : <Error403 title={'Shops Create'} perm={'shops_write'} />} />
                  <Route path='/shops/edit/:id' element={checkRole('shops_write') ? <ShopsEdit /> : <Error403 title={'Shops Edit'} perm={'shops_write'} />} />
                  <Route path='/smtp' element={checkRole('brand_read') ? <SMTP /> : <Error403 title={'SMTP'} perm={'brand_read'} />} />
                  <Route path='/smtp/:type' element={checkRole('brand_write') ? <SMTPEdit /> : <Error403 title={'SMTP Edit'} perm={'brand_write'} />} />
                  <Route path='/tickets' element={checkRole('tickets_read') ? <Tickets /> : <Error403 title={'Support Tickets'} perm={'tickets_read'} />} />
                  <Route path='/tfa/enable' element={<TFAEnable />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/login/tfa' element={<LoginTFA />} />
                  <Route path='/users' element={<Users />} />
                  <Route path='/users/:id' element={<User />} />
                  <Route path='/users/:user/bills' element={checkRole('bills_read') ? <UsersBills /> : <Error403 title={'User Purchases'} perm={'bills_read'} />} />
                  <Route path='/users/:user/logs' element={<UsersLogs />} />
                  <Route path='/users/:user/loyalty' element={<UsersLoyalty />} />
                  <Route path='/users/:user/redeems' element={<UsersRedeems />} />
                  <Route path='/users/:user/rfm_analysis' element={<RFMUser />} />
                  <Route path='/users/:user/force-redeem' element={checkRole('redeems_write') ? <UsersForceRedeems /> : <Error403 title={'Force Redeems'} perm={'redeems_write'} />} />
                  <Route path='*' element={<Error404 />} />
                </Routes>
              </Suspense>
            </AppContainer>
          )
          :
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/login/tfa' element={<LoginTFA />} />
            <Route path='/*' element={<Navigate to={`/login${(redirect && redirect.indexOf('login') === -1 && redirect !== '/') ? `?path=${redirect}` : ''}`} />} />
          </Routes>
      }
    </Provider >
  );
};

export default App;
